import { Injectable } from '@angular/core';
import { ProjectPartitionKey } from '@windsim/core/models/project-partition-key.model';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  public validateStringArgument(value: string, name: string): void {
    if (this.isNullOrEmpty(value)) {
      throw new Error(`The argument '${name}' cannot be null, empty or contain only white space.`);
    }
  }

  public validateProjectPartitionKey(value: ProjectPartitionKey): void {
    if (this.isNullOrEmpty(value.clientId)) {
      throw new Error(`The argument 'clientId' cannot be null, empty or contain only white space.`);
    }
    if (this.isNullOrEmpty(value.projectId)) {
      throw new Error(`The argument 'projectId' cannot be null, empty or contain only white space.`);
    }
  }

  public validateSector(value: number): void {
    if (!(value >= 0 && value <= 359)) {
      throw new Error(`The sector (${value}) is out of range. Please enter number between 0 and 359.`);
    }
  }

  public validateValue(value: any, name: string): void {
    if (typeof value === 'undefined' || value === null) {
      throw new Error(`The argument '${name}' is not declared or assigned.`);
    }
  }

  public validateFileName(filename: string, forbiddenSymbols: string[]): boolean {
    const regexSeparateNameFromExtension = /\.(?=[^\\.]+$)/;
    const baseName = filename.split(regexSeparateNameFromExtension)[0];
    if (baseName && forbiddenSymbols.some((symbol) => baseName.includes(symbol))) {
      return true;
    }
    return false;
  }

  public isNullOrEmpty(input: string): boolean {
    return !input || input === '' || input.length === 0 || input.trim().length === 0;
  }

  public showLog(input: string): boolean {
    const isNullOrEmpty = !input || input === '' || input.length === 0 || input.trim().length === 0;
    if (isNullOrEmpty) {
      return false;
    }
    console.log(input);
    const isFilterOutWarning =
      input.indexOf('read_dtm_windsimCell size in x and y-direction is not equal in .gws file') > 0 && input.endsWith('2989');
    if (isFilterOutWarning) {
      return false;
    }

    return true;
  }
}
