import { Injectable } from '@angular/core';
import userAccessPolicies from '@windsim-assets/dataset/user-access-policies.json';
import { PolicyGroup, ProjectAccessPolicy, UserAccessPolicies } from '@windsim/core/models';

@Injectable({
  providedIn: 'root',
})
export class CommonDefaults {
  public static readonly MAX_REQUEST_RETRIES = 3;
  public static readonly INITIAL_REQUEST_INTERVAL = 2000;
  public static readonly GLOBAL_MAPPER_SAFE_ZONE_IN_METERS = 1000;
  public static readonly GLOBAL_MAPPER_SAFE_ZONE_MODEL_SIZE_IN_METERS = 10000;
  public static readonly GLOBAL_MAPPER_SAFE_ZONE_FACTOR = 0.1;
  public static readonly NOTIFICATION_REFRESH_TIME_IN_SEC = 30;
  public static readonly PROJECT_BUTTON_REFRESH_TIME_IN_SEC = 30;
  public static readonly SECTOR_BASE_TIME = '0:00:00';
  public static readonly ZIPPED_PROJECT_FILE_NAME = 'project.zip';
  public static readonly PROJECT_FILE_EXTENSION = '.ws';
  public static readonly LAYOUT_LIST_FILE_NAME = 'LayoutList.xml';
  public static readonly DTM_LOG_DIRECTORY_AND_FILE_NAME = 'dtm/dtm.log';
  public static readonly GRID_FILE_EXTENSION = '.gws';
  public static readonly STATCRAFT_CLIENT_ID = '3886f18e-088d-47a2-8d45-18327da96d18';

  public static readonly CLIENT_ID_STORAGE_KEY = 'client-id';
  public static readonly USER_ID_STORAGE_KEY = 'user-id';
  public static readonly PROJECT_ID_STORAGE_KEY = 'project-id';
  public static readonly APPLICATION_STATE_STORAGE_KEY = 'application-state';
  public static readonly OWN_PROJECT_DETAILS_STORAGE_KEY = 'own-project-details';

  public static readonly TOKEN_STORAGE_KEY = 'token';
  public static readonly SOURCE_PATH_STORAGE_KEY = 'source-path';
  public static readonly IS_MARKERS_AREA_LOCKED_STORAGE_KEY = 'is-markers-area-locked';
  public static readonly MODEL_AREA_COORDS_STORAGE_KEY = 'model-area-coords';
  public static readonly MARKERS_STORAGE_KEY = 'markers';
  public static readonly MARKERS_LONGITUDE_COORDS_STORAGE_KEY = 'map-markers-longitude-coords';
  public static readonly MARKERS_LATITUDE_COORDS_STORAGE_KEY = 'map-markers-latitude-coords';
  public static readonly MAP_AREA_SELECTION_MODE = 'map-area-selection-mode';
  public static readonly MAP_MARKERS_MAX_AREA_BOUNDS_STORAGE_KEY = 'markers-max-area-bounds';
  public static readonly ELEVATION_STORAGE_KEY = 'elevation-dataset';
  public static readonly ROUGHNESS_STORAGE_KEY = 'roughness-dataset';
  public static readonly ROUGHNESSLEGEND_STORAGE_KEY = 'roughness-legend-dataset';
  public static readonly BUFFER_AREA_COORDS_STORAGE_KEY = 'buffer-area-coords';

  public static readonly DATASET_HEADER_STORAGE_KEY = 'dataset-header';
  public static readonly DATASET_NAME_STORAGE_KEY = 'dataset-name';
  public static readonly DATASET_COORDS_STORAGE_KEY = 'dataset-coords';

  public static readonly MODEL_FORM_STORAGE_KEY = 'model-form';
  public static readonly MODEL_JOB_ID_STORAGE_KEY = 'model-job-id';
  public static readonly GRID_HEADER_STORAGE_KEY = 'grid-header';
  public static readonly MODEL_LAYERS_STORAGE_KEY = 'model-layers';
  public static readonly LAYERS_LEGEND_BUFFER_STORAGE_KEY = 'layers-legend-buffer';
  public static readonly FIELD_VALUE_LEGEND_BUFFER_STORAGE_KEY = 'field-value-legend-buffer';
  public static readonly MODEL_STATUS_KEY = 'model-status';

  public static readonly SIMULATION_FORM_STORAGE_KEY = 'simulations-form';
  public static readonly SIMULATION_STATUS_KEY = 'simulation-status';
  public static readonly SIMULATION_SECTORS_STORAGE_KEY = 'simulation-sectors';
  public static readonly SIMULATION_SELECTED_SECTOR_STORAGE_KEY = 'simulation-selected-sector';
  public static readonly SIMULATION_LAYER_STORAGE_KEY = 'simulation-layer';
  public static readonly SIMULATION_JOB_RERUN_COUNTER_KEY = 'simulation-job-rerun-counter';

  public static readonly PLOT_STORAGE_SERVICE = 'plot-values';

  public static readonly ALERT_ID = 'alert-message';

  public static readonly TABLE_PAGINATION_SIZES = [10, 25, 50, 100];
  public static readonly TABLE_PAGINATION_DEFAULT_SIZE = 10;

  public static readonly LOGGED_USER = 'logged-user';

  private static readonly policies = userAccessPolicies as UserAccessPolicies;
  private static readonly policyGroup = CommonDefaults.policies.ProjectUserPolicies as PolicyGroup;
  private static readonly defaultProjectRole = CommonDefaults.policyGroup.DefaultRole as ProjectAccessPolicy;
  private static readonly projectOwnerRole = CommonDefaults.policyGroup.ProjectOwner as ProjectAccessPolicy;
  public static readonly DEFAULT_PROJECT_ROLE = CommonDefaults.defaultProjectRole;
  public static readonly PROJECT_OWNER_ROLE = CommonDefaults.projectOwnerRole;

  public static readonly IS_FEATURE_ENABLED_IN_HYBRID_RELEASE = false;

  public static readonly WINDSIM_CLOUD_TAB_TITLE = 'WindSim Cloud';
  public static readonly ACCELERATOR_TAB_TITLE = 'WindSim Accelerator';
  public static readonly CONTACT_EMAIL = 'sales@windsim.com';
  public static readonly SERVER_SIDE_ERROR_MESSAGE = 'There is something wrong, try again in few moments, or contact our support team.';
  public static readonly TERMS_AND_CONDITIONS_LINK =
    'https://25043726.fs1.hubspotusercontent-eu1.net/hubfs/25043726/WindSim%20Accelerator%20DPA%20030522.pdf';

  public static readonly NO_ACTIVE_SUBSCRIPTION_FOUND_ERROR_MESSAGE = 'No active subscription found';

  public static readonly MAX_SECTOR_CALCULATION_RERUNS = 3;
  public static readonly PROJECT_BOUNDARY_SIZE = 4000000;

  public static readonly EMAIL_REGEX_PATTERN = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;

  public static readonly FORBIDDEN_FILENAME_SYMBOLS = [
    '/',
    '\\',
    '?',
    '%',
    '*',
    '|',
    '"',
    '<',
    '>',
    '.',
    ',',
    ':',
    ';',
    '=',
    'Æ',
    'æ',
    'Ø',
    'ø',
    'Å',
    'å',
  ];
}
