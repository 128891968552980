export enum ModelFileType {
  GridXY,
  GridZ,
  OpenArea,
  Elevation,
  Roughness,
  RoughnessLog,
  Inclination,
  SecondOrderDerivative,
  FieldElevation,
  Terrain,
  DeltaElevation,
}
