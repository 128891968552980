import { ModelFileType, ShaderType } from '@windsim-model/enums';
import { ModelLayer } from '@windsim-model/models';

export const predefinedModelTerrainLayers: ModelLayer[] = [
  {
    layerName: 'Elevation',
    geoFileName: 'dtm/view.geo',
    sclFileName: 'dtm/view_height.scl',
    fileType: ModelFileType.Elevation,
    shader: ShaderType.ElevationLayerShader,
    hasDataGrid: false,
    isActive: true,
    isVisible: false,
    hasLegend: true,
    opacity: 1,
    position: 1,
  },
  {
    layerName: 'Roughness',
    geoFileName: 'dtm/view.geo',
    sclFileName: 'dtm/view_roughness.scl',
    fileType: ModelFileType.Roughness,
    shader: ShaderType.RoughnessLayerShader,
    hasDataGrid: false,
    isActive: true,
    isVisible: false,
    hasLegend: true,
    opacity: 1,
    position: 2,
  },
  {
    layerName: 'Roughness log',
    geoFileName: 'dtm/view.geo',
    sclFileName: 'dtm/view_roughness_log.scl',
    fileType: ModelFileType.RoughnessLog,
    shader: ShaderType.RoughnessLogLayerShader,
    hasDataGrid: false,
    isActive: true,
    isVisible: false,
    hasLegend: true,
    opacity: 1,
    position: 3,
  },
  {
    layerName: 'Inclination',
    geoFileName: 'dtm/view.geo',
    sclFileName: 'dtm/view_inclination.scl',
    fileType: ModelFileType.Inclination,
    shader: ShaderType.InclinationLayerShader,
    hasDataGrid: false,
    isActive: true,
    isVisible: false,
    hasLegend: true,
    opacity: 1,
    position: 4,
  },
  {
    layerName: 'Second order derivative',
    geoFileName: 'dtm/view.geo',
    sclFileName: 'dtm/view_max_derivative_second.scl',
    fileType: ModelFileType.SecondOrderDerivative,
    shader: ShaderType.SecondOrderDerivativeLayerShader,
    hasDataGrid: false,
    isActive: true,
    isVisible: false,
    hasLegend: true,
    opacity: 1,
    position: 5,
  },
  {
    layerName: 'Delta elevation',
    geoFileName: 'dtm/dtm.geo',
    sclFileName: 'dtm/dtm_height_delta_smooth.scl',
    fileType: ModelFileType.DeltaElevation,
    shader: ShaderType.DeltaElevationLayerShader,
    hasDataGrid: false,
    isActive: true,
    isVisible: false,
    hasLegend: true,
    opacity: 1,
    position: 6,
  },
];

export const predefinedModelDataLayers: ModelLayer[] = [
  {
    layerName: 'Grid (xy)',
    geoFileName: 'dtm/view.geo',
    sclFileName: 'dtm/view_height.scl',
    fileType: ModelFileType.GridXY,
    shader: ShaderType.WireframeShader,
    hasDataGrid: true,
    isActive: true,
    isVisible: false,
    hasLegend: false,
    position: 7,
  },
  {
    layerName: 'Grid (z)',
    geoFileName: 'dtm/z_distribution_2d.geo',
    sclFileName: '',
    fileType: ModelFileType.GridZ,
    shader: ShaderType.WireframeShader,
    hasDataGrid: true,
    isActive: true,
    isVisible: false,
    hasLegend: false,
    position: 8,
  },
  {
    layerName: 'Open area',
    geoFileName: 'dtm/open_area_2d.geo',
    sclFileName: '',
    fileType: ModelFileType.OpenArea,
    shader: ShaderType.WireframeShader,
    hasDataGrid: true,
    isActive: true,
    isVisible: false,
    hasLegend: false,
    position: 9,
  },
  // {
  //   layerName: 'Extension .gws',
  //   geoFileName: 'dtm/grid_plane.geo',
  //   sclFileName: 'dtm/grid_height.scl',
  //   fileType: ModelFileType.FieldElevation,
  //   shader: ShaderType.ExtensionLayerShader,
  //   hasDataGrid: true,
  //   isActive: true,
  //   isVisible: false,
  //   hasLegend: true,
  //   position: 10,
  // },
];
